import { useCallback } from "react";

import { Box, Divider, Stack, Typography } from "@mui/material";

import { TGalleryItem } from "./types";

export type TGalleryItemProps = {
  item: TGalleryItem;
  isActive: boolean;
  isLast: boolean;
  onHeaderClick: (id: TGalleryItem["id"]) => void;
};

export const GalleryItem = ({
  item,
  isActive,
  isLast,
  onHeaderClick,
}: TGalleryItemProps) => {
  const onClick = useCallback(
    () => onHeaderClick(item.id),
    [item.id, onHeaderClick]
  );

  return (
    <Stack
      key={item.id}
      gap={{
        xs: 1.5,
        md: 4,
      }}
    >
      <Box
        onClick={onClick}
        minWidth={300}
        sx={{
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        <Typography variant="h6" color={isActive ? "primary" : "text.disabled"}>
          {item.title}
        </Typography>
      </Box>

      {!isLast && <Divider sx={{ backgroundColor: "#FFF", opacity: 0.1 }} />}
    </Stack>
  );
};

export const HEADER_HEIGHT = 56;
