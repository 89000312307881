import { useCallback } from "react";

import { ButtonProps, SxProps } from "@mui/material";
import { APP_PADRE_URL } from "src/config";
import { useMixpanelContext, EButtonId } from "src/contexts/Mixpanel";
import { useEnrichedUrl } from "src/hooks/useEnrichedUrl";
import { useMdOrUp } from "src/themes";

import { DecorativeButton } from "../DecorativeButton";

type TActionButtonProps = Partial<ButtonProps> & {
  buttonId: EButtonId;
  label?: string;
  sx?: SxProps;
};

export const ActionButton = ({
  buttonId,
  label = "Trade now",
  sx = {},
  ...buttonProps
}: TActionButtonProps) => {
  const enrichedUrl = useEnrichedUrl(APP_PADRE_URL);
  const isMdOrUp = useMdOrUp();
  const { trackButtonClicked } = useMixpanelContext();

  const onClick = useCallback(() => {
    trackButtonClicked(buttonId);
  }, [trackButtonClicked, buttonId]);

  return (
    <DecorativeButton
      onClick={onClick}
      href={enrichedUrl}
      sx={{
        minWidth: DEFAULT_MIN_WIDTH,
        maxWidth: { xs: 213, md: 234 },
        ...sx,
      }}
      size={isMdOrUp ? "large" : "medium"}
      {...buttonProps}
    >
      {label}
    </DecorativeButton>
  );
};

export const DEFAULT_MIN_WIDTH = { xs: 140, md: 190 };
