import {
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";

import { IElementDimensions } from "./useElementDimensions.model";

const useElementDimensions = <T extends HTMLElement>(
  ref: RefObject<T | undefined>
) => {
  const [elementDimensions, setElementDimensions] =
    useState<IElementDimensions>({
      width: 0,
      height: 0,
    });

  const handleResize = useCallback(() => {
    if (ref.current) {
      setElementDimensions({
        width: ref.current.offsetWidth,
        height: ref.current.offsetHeight,
      });
    }
  }, [ref]);

  useEffect(() => {
    setTimeout(() => handleResize(), 1000);
  }, [handleResize]);

  useLayoutEffect(() => {
    handleResize();
  }, [handleResize]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return elementDimensions;
};

export default useElementDimensions;
