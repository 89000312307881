import { PropsWithChildren } from "react";

import { Box, Button, ButtonProps, Typography, useTheme } from "@mui/material";

type TShadeButtonProps = ButtonProps & PropsWithChildren;

export const ShadeButton = ({
  children,
  ...buttonProps
}: TShadeButtonProps) => {
  const theme = useTheme();
  return (
    <Button
      variant="outlined"
      {...buttonProps}
      sx={{
        color: theme.palette.text.disabled,
        position: "relative",
        borderColor: "transparent",
        backgroundColor: "transparent",
        transition: `all ${TRANSITION_DURATION}s ease`,
        borderRadius: "6px",
        "&:hover": {
          color: theme.palette.text.primary,
          borderColor: "#313137",
          backgroundColor: "#18181B",
          "&::after": {
            opacity: 1,
          },
        },
        "&::after": {
          content: '""',
          position: "absolute",
          top: -1,
          left: -1,
          right: -1,
          bottom: -1,
          background: `linear-gradient(to bottom, transparent, rgba(12, 12, 12, 0.75))`,
          zIndex: 1,
          pointerEvents: "none",
          borderRadius: "6px",
          transition: `all ${TRANSITION_DURATION}s ease`,
          opacity: 0,
        },
        ...buttonProps.sx,
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          color: "inherit",
          position: "relative",
          zIndex: 2,
        }}
      >
        <Typography color="inherit" variant="body2">
          {children}
        </Typography>
      </Box>
    </Button>
  );
};

const TRANSITION_DURATION = 0.4;
