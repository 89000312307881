import { Box } from "@mui/material";
import { useMdOrUp } from "src/themes";

import essenceImage from "./images/essence.png";

export const EssenceImage = () => {
  const mdOrUp = useMdOrUp();

  // add fancy animation
  const content = (
    <Box
      component="img"
      src={essenceImage}
      alt="essence"
      position={mdOrUp ? "absolute" : "relative"}
      zIndex={1}
      {...(mdOrUp
        ? {
            bottom: -160,
            right: -50,
          }
        : {
            width: 841,
            height: "auto",
            overflow: "hidden",
          })}
    />
  );

  if (!mdOrUp) {
    return (
      <Box
        sx={{
          width: 841,
          height: 350,
          overflow: "hidden",
          position: "relative",
        }}
      >
        {content}
      </Box>
    );
  }

  return content;
};
