import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { LEGAL_NOTICE_LINE_HEIGHT } from "src/components/LegalNotice/styles";

import { PadreText } from "./PadreText";

export const TosSubheader = () => {
  const theme = useTheme();

  return (
    <Stack>
      <Typography
        variant="body2"
        textAlign="center"
        component="p"
        color={theme.palette.text.secondary}
        lineHeight={LEGAL_NOTICE_LINE_HEIGHT}
      >
        The <PadreText /> Services Agreement is an agreement between you and{" "}
        <PadreText /> that governs your use of <PadreText /> online services.
      </Typography>
      <Typography
        variant="body2"
        textAlign="center"
        textTransform="uppercase"
        sx={{ mt: 3 }}
      >
        The parties of the agreement are:
      </Typography>
      <Typography
        variant="body2"
        textAlign="center"
        component="p"
        color={theme.palette.text.secondary}
        sx={{ mt: 0.5 }}
        lineHeight={LEGAL_NOTICE_LINE_HEIGHT}
      >
        "<PadreText /> and the services that they provide that include, but are
        not limited to, a decentralized graphical interface and any application
        created and distributed by <PadreText />" hereinafter called "
        <PadreText /> Services" and "The users of these services and
        applications" hereinafter called "Users".
      </Typography>
    </Stack>
  );
};
