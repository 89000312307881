import { Breakpoint, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const breakpointHookFactory = (breakpointValue: Breakpoint | number) => () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up(breakpointValue));
};

export const useSmOrUp = breakpointHookFactory("sm");
export const useMdOrUp = breakpointHookFactory("md");
export const useLgOrUp = breakpointHookFactory("lg");
export const useXlOrUp = breakpointHookFactory("xl");
