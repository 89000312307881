import { noop } from "lodash";
import { Config } from "mixpanel-browser";

import { TMixpanelContext } from "./MixpanelContext";

export const MIXPANEL_TOKEN =
  process.env.NODE_ENV === "production"
    ? "f259317776e8d4d722cf5f6de613d9b5"
    : null;

export const MIXPANEL_CONFIG: Partial<Config> = {
  api_host: "https://mp-proxy-qa6saklotq-uc.a.run.app",
};

export const BUTTON_CLICKED_EVENT_NAME = "LandingButtonClicked";
export const PAGE_VIEW_EVENT_NAME = "LandingPageView";

export const MIXPANEL_COMMON_PROPERTIES = {
  appVersion: "landing",
};

export const EMPTY_MIXPANEL_CONTEXT_VALUE: TMixpanelContext = {
  mixpanel: null,
  trackButtonClicked: noop,
};
