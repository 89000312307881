import { LogoWhite } from "src/assets";
import { ROOT_APP_PATH } from "src/config";

import { DEFAULT_LOGO_SIZE } from "./config";

export const LogoLinkWhite = () => (
  <a href={ROOT_APP_PATH}>
    <LogoWhite {...DEFAULT_LOGO_SIZE} height={32} />
  </a>
);
