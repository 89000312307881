import { ReactNode } from "react";

import Typography from "@mui/material/Typography";

interface ITosTextProps {
  children: ReactNode;
  bold?: boolean;
  inline?: boolean;
}

export const LegalNoticeText = ({
  children,
  bold = false,
  inline = false,
}: ITosTextProps) => {
  return (
    <Typography
      variant="body2"
      component={inline ? "span" : "p"}
      fontSize="inherit"
      lineHeight="inherit"
      fontWeight={bold ? 600 : 500}
    >
      {children}
    </Typography>
  );
};
