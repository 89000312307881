import { PropsWithChildren } from "react";

import { Box, BoxProps, useTheme } from "@mui/material";

type TGradientBoxProps = PropsWithChildren &
  BoxProps & {
    shadeStrength?: number;
    showGlowOnHover?: boolean;
    mainColor?: string;
    borderColor?: string;
    innerBoxProps?: BoxProps;
  };

export const GradientBox = ({
  shadeStrength = DEFAULT_SHADE_STRENGTH,
  showGlowOnHover = false,
  mainColor,
  borderColor,
  borderRadius,
  children,
  innerBoxProps,
  ...boxProps
}: TGradientBoxProps) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      px={DEFAULT_PADDING}
      pt={DEFAULT_PADDING}
      {...boxProps}
      sx={{
        position: "relative",
        backgroundColor: mainColor ?? theme.palette.background.paper,
        border: `${BORDER_WIDTH}px solid ${borderColor ?? BORDER_COLOR}`,
        borderRadius: borderRadius ?? BORDER_RADIUS,
        "&::after": {
          content: '""',
          position: "absolute",
          top: -BORDER_WIDTH,
          left: -BORDER_WIDTH,
          right: -BORDER_WIDTH,
          bottom: -BORDER_WIDTH,
          background: `linear-gradient(to bottom, transparent, rgba(12, 12, 12, ${shadeStrength}))`,
          zIndex: 1,
          pointerEvents: "none",
          borderRadius: BORDER_RADIUS,
        },
        ...(showGlowOnHover
          ? {
              "&:hover": {
                backgroundColor: "#241B18",
                borderColor: "#493730",
              },
            }
          : {}),
        ...boxProps.sx,
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
        {...innerBoxProps}
        sx={{
          position: "relative",
          zIndex: 2,
          ...innerBoxProps?.sx,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

const BORDER_RADIUS = "6px";
const DEFAULT_PADDING = "60px";

const BORDER_COLOR = "#313137";
const DEFAULT_SHADE_STRENGTH = 0.5;
const BORDER_WIDTH = 1;
