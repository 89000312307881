import { useEffect, useRef, useState } from "react";

import { Box, Stack } from "@mui/material";
import { Loading } from "src/components/Loading";
import { useLgOrUp, useXlOrUp } from "src/themes";

export const HeroVideoPlayer = () => {
  const lgOrUp = useLgOrUp();
  const xlOrUp = useXlOrUp();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleVideoLoad = () => {
      setIsLoading(false);
    };

    const videoElement = videoRef.current;

    if (videoElement) {
      videoElement.addEventListener("loadeddata", handleVideoLoad);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener("loadeddata", handleVideoLoad);
      }
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        width: "auto",
        height: "100%",
        overflow: "hidden",
        justifyContent: "flex-end",
      }}
    >
      {isLoading && (
        <Stack
          sx={{
            minWidth: lgOrUp ? (xlOrUp ? XL_WIDTH : LG_WIDTH) : undefined,
          }}
          minHeight={350}
        >
          <Loading />
        </Stack>
      )}
      <video
        ref={videoRef}
        src="/heroVideo.webm"
        style={{
          display: isLoading ? "none" : "block",
          width: lgOrUp
            ? `min(100%, ${xlOrUp ? XL_WIDTH : LG_WIDTH}px)`
            : "100%",
          minWidth: lgOrUp ? (xlOrUp ? XL_WIDTH : LG_WIDTH) : undefined,
          maxWidth: xlOrUp ? XL_WIDTH : undefined,
          height: "auto",
          border: "1px solid rgba(24, 24, 24, 0.4)",
          borderRadius: "8px",
        }}
        loop
        muted
        autoPlay
        playsInline
        controls={false}
      />
    </Box>
  );
};

const XL_WIDTH = 644;
const LG_WIDTH = 600;
