import { createTheme } from "@mui/material/styles";

export const BASE_UNIT = 8;

const { palette, breakpoints } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: string) =>
  augmentColor({ color: { main: mainColor } });

const MAIN_FONT = "RFDewiExpanded";
const INTER_FONT = "Inter";

export const BUTTON_SIZE_SMALL = 34;
/*
 * Custom theme ambient declaration available at ./theme.models.ts.
 */
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    mode: "dark",
    divider: "#424349",
    background: {
      default: "#0C0C0C", //
      paper: "#1b1b1b", //
      paperDark: "#181C24",
      modal: "#16162A",
      modalFooter: "#171723",
      dividerLight: "#1E1E33",
      divider: "#CCCCCC",
      tooltip: "#292946",
      decorativeLabel: "#093E53",
      header: "#0C0C0C",
    },
    primary: createColor("#FF7324"),
    text: {
      primary: "#FFF",
      secondary: "#D9D9D9", //
      disabled: "#9B9B9B", //
    },
    border: {
      main: "#2D2D3E",
    },
    icon: {
      main: "#D9D9D9",
    },
  },
  typography: {
    fontFamily: `${MAIN_FONT}, sans-serif`,
    allVariants: {
      color: "#FFF",
    },
    h1: {
      fontSize: 64,
      [breakpoints.down("md")]: {
        fontSize: 22,
      },
      lineHeight: 1,
      fontWeight: 600,
      textTransform: "uppercase",
    },
    h2: {
      fontSize: 48,
      [breakpoints.down("md")]: {
        fontSize: 20,
      },
      lineHeight: 1,
      fontWeight: 600,
      textTransform: "uppercase",
    },
    h3: {
      fontSize: 40,
      [breakpoints.down("md")]: {
        fontSize: 18,
      },
      lineHeight: 1.2,
      fontWeight: 600,
      textTransform: "uppercase",
    },
    h4: {
      fontSize: 32,
      [breakpoints.down("md")]: {
        fontSize: 16,
      },
      lineHeight: 1.2,
      fontWeight: 600,
      textTransform: "uppercase",
    },
    h5: {
      fontSize: 24,
      [breakpoints.down("md")]: {
        fontSize: 14,
      },
      lineHeight: 1.2,
      fontWeight: 600,
      textTransform: "uppercase",
    },
    h6: {
      fontSize: 20,
      [breakpoints.down("md")]: {
        fontSize: 12,
      },
      lineHeight: 1.2,
      fontWeight: 600,
      textTransform: "uppercase",
    },
    caption: {
      fontSize: 16,
      [breakpoints.down("md")]: {
        fontSize: 12,
      },
      lineHeight: 1.2,
      fontWeight: 600,
      textTransform: "uppercase",
    },
    body1: {
      fontFamily: `${INTER_FONT}, sans-serif`,
      fontSize: 20,
      [breakpoints.down("md")]: {
        fontSize: 16,
      },
      lineHeight: 1.4,
      fontWeight: 400,
    },
    body2: {
      fontFamily: `${INTER_FONT}, sans-serif`,
      fontSize: 16,
      [breakpoints.down("md")]: {
        fontSize: 14,
      },
      lineHeight: 1.4,
      fontWeight: 400,
    },
  },
  shape: {
    borderRadius: 4,
  },
  spacing: 8,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          textTransform: "none",
        },
        sizeLarge: {
          fontSize: 16,
          height: 50,
        },
        sizeMedium: {
          fontSize: 13,
          height: 40,
        },
        sizeSmall: {
          fontSize: 13,
          height: BUTTON_SIZE_SMALL,
        },
        outlined: {
          borderWidth: 1,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: 12,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          backgroundImage: "none",
          borderRadius: 0,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "divider",
        },
      },
    },
  },
});

export default theme;
