import { Grid, Stack, Typography } from "@mui/material";
import { GradientBox } from "src/components/GradientBox";
import { GradientTypography } from "src/components/GradientTypography";
import { useLgOrUp, useSmOrUp, useMdOrUp } from "src/themes";

import { FeaturesIntroBoxContent } from "./FeaturesIntroBoxContent";
import antiRugImage from "./images/AntiRug.png";
import limitOrdersImage from "./images/LimitOrders.png";
import multiChainImage from "./images/MultiChain.png";
import portfolioMonitoringImage from "./images/PortfolioMonitoring.png";
import quickSwapsImage from "./images/QuickSwaps.png";
import unlockableSessionsImage from "./images/UnlockableSessions.png";

export const FeaturesIntroSection = () => {
  const mdOrUp = useMdOrUp();
  const smOrUp = useSmOrUp();
  const lgOrUp = useLgOrUp();

  return (
    <Stack
      alignItems="center"
      my={{
        xs: 7.5,
        md: 30,
      }}
      gap={{
        xs: 3,
        md: 15,
      }}
    >
      <Stack alignItems="center" gap={1.25}>
        <Typography
          color="primary.main"
          variant="caption"
          textAlign="center"
          fontSize={{
            xs: 14,
            md: 16,
          }}
          fontWeight={600}
        >
          PADRE APP
        </Typography>

        <GradientTypography variant="h2" textAlign="center">
          built for
          {mdOrUp ? " " : <br />}
          everyday use
        </GradientTypography>
      </Stack>

      <Stack
        direction="row"
        gap={1}
        width="100%"
        maxWidth={1800}
        px={{
          xs: 0,
          md: 6,
          lg: 8,
          xl: 24,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={4}>
            <GradientBox
              height={smOrUp ? BIG_BOX_HEIGHT : MOBILE_BOX_HEIGHT}
              {...gradientBoxCommonProps}
            >
              <FeaturesIntroBoxContent
                title="multi-chain"
                text={
                  "Ethereum, Base, Solana support. Seamless transition between chains - no need to switch between different apps."
                }
                imgSrc={multiChainImage}
              />
            </GradientBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <GradientBox
              height={
                smOrUp
                  ? !lgOrUp
                    ? BIG_BOX_HEIGHT
                    : SMALL_BOX_HEIGHT
                  : MOBILE_BOX_HEIGHT
              }
              {...gradientBoxCommonProps}
            >
              <FeaturesIntroBoxContent
                title="quick-swaps"
                text={
                  "Every transaction is simulated before being submitted to blockchain to minimize number of reverts."
                }
                imgSrc={quickSwapsImage}
              />
            </GradientBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <GradientBox
              height={smOrUp ? BIG_BOX_HEIGHT : MOBILE_BOX_HEIGHT}
              {...gradientBoxCommonProps}
            >
              <FeaturesIntroBoxContent
                title="limit orders"
                text={
                  "Set up multiple buy dip, take profit or stop loss levels seamlessly. Track active orders on advanced TradingView's candlestick charts."
                }
                imgSrc={limitOrdersImage}
              />
            </GradientBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <GradientBox
              height={
                smOrUp
                  ? !lgOrUp
                    ? BIG_BOX_HEIGHT
                    : SMALL_BOX_HEIGHT
                  : MOBILE_BOX_HEIGHT
              }
              {...gradientBoxCommonProps}
            >
              <FeaturesIntroBoxContent
                title="portfolio monitoring"
                text={
                  "Track your profits real-time. Know the expected payout after sell tax and gas fee estimates before the swap."
                }
                imgSrc={portfolioMonitoringImage}
              />
            </GradientBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <GradientBox
              height={smOrUp ? BIG_BOX_HEIGHT : MOBILE_BOX_HEIGHT}
              mt={`${lgOrUp ? SMALL_BOX_HEIGHT - BIG_BOX_HEIGHT : 0}px`}
              {...gradientBoxCommonProps}
            >
              <FeaturesIntroBoxContent
                title="unlockable sessions"
                text={
                  "Full control of your wallets: With Turnkey private key infrastructure, Padre gives you best of both worlds when it comes to security and convenience."
                }
                imgSrc={unlockableSessionsImage}
              />
            </GradientBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <GradientBox
              height={smOrUp ? SMALL_BOX_HEIGHT : MOBILE_BOX_HEIGHT}
              {...gradientBoxCommonProps}
            >
              <FeaturesIntroBoxContent
                title="anti-rug protection"
                text={
                  "Powered by BloxRoute MEV protection service on Ethereum and Solana, Padre saves you from sandwich attacks."
                }
                imgSrc={antiRugImage}
              />
            </GradientBox>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
};

const BIG_BOX_HEIGHT = 400;
const SMALL_BOX_HEIGHT = 360;
const MOBILE_BOX_HEIGHT = 305;

const gradientBoxCommonProps = {
  alignItems: "flex-start",
  pt: 4,
  shadeStrength: 0.8,
  showGlowOnHover: true,
};
