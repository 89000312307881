import { SxProps } from "@mui/material/styles";

import { ExternalLinkBehavior } from "./ExternalLinkBehavior";

interface IExternalLinkProps {
  label: string;
  href: string;
  nofollow?: boolean;
  sx?: SxProps;
}

export const ExternalTextLink = ({
  label,
  href,
  nofollow = true,
  sx,
}: IExternalLinkProps) => {
  return (
    <ExternalLinkBehavior href={href} nofollow={nofollow} sx={sx}>
      {label}
    </ExternalLinkBehavior>
  );
};
