import { memo, useEffect } from "react";
import { useNavigate } from "react-router-dom";

type TSimpleRedirectProps = {
  path: string;
};

/**
 * According to react-router-dom doc: <Navigate> component should be used only
 * in class-based legacy components. Otherwise, it is recommended to take
 * advantage of useNavigate() hook.
 */
export const SimpleRedirect = memo(({ path }: TSimpleRedirectProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(path);
  }, [path, navigate]);

  return null;
});
