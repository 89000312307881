import { ExternalTextLink } from "src/components/ExternalLink";
import { LegalNoticeText } from "src/components/LegalNotice";
import { IArticleConfig } from "src/components/LegalNotice/types";

const TRADING_VIEW_ATTRIBUTION_LINK = "https://www.tradingview.com/";
const TRADING_VIEW_ECONOMIC_CALENDAR_LINK =
  "https://www.tradingview.com/economic-calendar/";

export const TOS_ARTICLES: IArticleConfig[] = [
  {
    title: "General disclaimer",
    content: (
      <>
        <LegalNoticeText>
          All PADRE services and their contents are provided "as is" and "as
          available" without any warranty or representations of any kind,
          whether express or implied. PADRE is a distributor and not a publisher
          of the content supplied by third parties; as such, PADRE exercises no
          editorial control over such content and makes no warranty or
          representation as to the accuracy, reliability or currency of any
          information, content, service or merchandise provided through or
          accessible via the PADRE Service.
        </LegalNoticeText>
        <LegalNoticeText>
          Without limiting the foregoing, PADRE specifically disclaims all
          warranties and representations in any content transmitted on or in
          connection with the PADRE Service or on sites that may appear as links
          on the PADRE Service, or in the products provided as a part of, or
          otherwise in connection with, the PADRE Service, including without
          limitation any warranties of merchantability, fitness for a particular
          purpose or non-infringement of third party rights.
        </LegalNoticeText>
        <LegalNoticeText>
          No oral advice or written information given by PADRE or any of its
          affiliates, employees, officers, directors, agents, or the like will
          create a warranty. Price and availability information is subject to
          change without notice. Without limiting the foregoing, PADRE does not
          warrant that the PADRE Service will be uninterrupted, uncorrupted,
          timely, or error-free.
        </LegalNoticeText>
      </>
    ),
  },
  {
    title: "Limited liability disclaimer",
    content: (
      <>
        <LegalNoticeText>
          PADRE endeavors to update and/or supplement the content of the website
          on a regular basis. Despite our care and attention, content may be
          incomplete and/or incorrect. The materials offered on the website are
          offered without any form of guarantee or claim to their correctness.
          These materials can be changed at any time without prior notice from
          PADRE.
        </LegalNoticeText>
        <LegalNoticeText>
          Particularly, all prices on the website are stated subject to typing
          and programming errors. No liability is assumed for the implications
          of such errors. No agreement is concluded on the basis of such errors.
        </LegalNoticeText>
        <LegalNoticeText>
          PADRE shall not bear any liability for hyperlinks to websites or
          services of third parties included on the website. From our website,
          you can visit other websites by following hyperlinks to such external
          sites. While we strive to provide only quality links to useful and
          ethical websites, we have no control over the content and nature of
          these sites. These links to other websites do not imply a
          recommendation for all the content found on these sites. Site owners
          and content may change without notice and may occur before we have the
          opportunity to remove a link that may have gone "bad".
        </LegalNoticeText>
        <LegalNoticeText>
          Please be also aware that when you leave our website, other sites may
          have different privacy policies and terms which are beyond our
          control. Please be sure to check the Privacy Policies of these sites
          as well as their "Terms of Service" before engaging in any business or
          uploading any information.
        </LegalNoticeText>
        <LegalNoticeText>
          In no event shall the owners of, or contributors to, PADRE be liable
          for any damages of any kind, including, but not limited to, loss of
          use, loss of profits, or loss of data, whether in an action in
          contract, tort, or otherwise, arising out of or in connection with the
          use of, or inability to use, the PADRE service.
        </LegalNoticeText>
      </>
    ),
  },
  {
    title: "Risks related to the use of padre",
    content: (
      <>
        <LegalNoticeText>
          PADRE will not be held accountable for any losses, damages, or claims
          stemming from events that fall under the following categories:
        </LegalNoticeText>
        <ol>
          <li>
            Mistakes made by the user of any PADRE-related software or service,
            e.g., forgotten passwords, payments sent to wrong PADRE addresses,
            and accidental deletion of wallets. Users must ensure their own
            vigilance.
          </li>
          <li>
            Security problems experienced by the user of any PADRE-related
            software or service, e.g., unauthorized access to users' wallets
            and/or accounts. Users should maintain their own stringent security
            measures.
          </li>
          <li>
            Software problems of the website and/or any PADRE-related software
            or service, e.g., corrupted wallet file, incorrectly constructed
            transactions, unsafe cryptographic libraries, malware affecting the
            website and/or any PADRE-related software or service. Users should
            exercise caution and ensure their own cybersecurity.
          </li>
          <li>
            Technical failures in the hardware of the user of any PADRE-related
            software or service, e.g., data loss due to a faulty or damaged
            storage device. Your use of our products and services is solely at
            your discretion and risk.
          </li>
          <li>
            Actions or inactions of third parties and/or events experienced by
            third parties, e.g., the bankruptcy of service providers,
            information security attacks on service providers, and fraud
            conducted by third parties. Users should take their own precautions.
          </li>
        </ol>
      </>
    ),
  },
  {
    title: "Copyright disclaimer",
    content: (
      <LegalNoticeText>
        All intellectual property rights to these materials are vested in PADRE.
        Copying, distribution and any other use of these materials is not
        permitted without written permission from PADRE, except and only to the
        extent otherwise provided in regulations of mandatory law (such as the
        right to quote), unless otherwise indicated for specific materials.
      </LegalNoticeText>
    ),
  },
  {
    title: "Errors and omissions disclaimer",
    content: (
      <LegalNoticeText>
        We are not responsible for any content, code or any other imprecision.
        We do not provide warranties or guarantees. In no event shall we be
        liable for any special, direct, indirect, consequential, or incidental
        damages or any damages whatsoever, whether in an action of contract,
        negligence or other tort, arising out of or in connection with the use
        of the Service or the contents of the Service. We reserve the right to
        make additions, deletions, or modifications to the contents of the
        Service at any time without prior notice.
      </LegalNoticeText>
    ),
  },
  {
    title: "Links to other websites or channels disclaimer",
    content: (
      <>
        <LegalNoticeText>
          This Disclaimer applies only to the PADRE Services. PADRE Services may
          contain links to other websites, channels and groups not operated or
          controlled by us. We are not responsible for the content, accuracy or
          opinions expressed in such websites, and such websites, channels and
          groups are not investigated, monitored or checked for accuracy or
          completeness by us. Please remember that when you use a link to go
          from the PADRE Services to another website, channel or group, our
          Privacy Policy is no longer in effect.
        </LegalNoticeText>
        <LegalNoticeText>
          Your browsing and interaction on any other website, including those
          that have a link on our platform, is subject to that website's own
          rules and policies. Such third parties may use their own cookies or
          other methods to collect information about you. If you click on a
          third-party link, you will be directed to that third-party's site. We
          strongly advise you to review the Privacy Policy and Terms of every
          site you visit. We have no control over and assume no responsibility
          for the content, privacy policies or practices of any third-party
          sites or services.
        </LegalNoticeText>
      </>
    ),
  },
  {
    title: "Changes to our disclaimer",
    content: (
      <LegalNoticeText>
        We can update, amend, or make any changes to this document so that it
        accurately reflects our Service and policies. Unless otherwise required
        by law, those changes will be prominently posted here. Then, if you
        continue to use the Service, you will be bound by the updated
        Disclaimer. If you do not want to agree to this or any updated
        Disclaimer, you can delete your account.
      </LegalNoticeText>
    ),
  },
  {
    title: "Your consent",
    content: (
      <LegalNoticeText>
        We've updated our Disclaimer to provide you with complete transparency
        into what is being set when you visit our site and how it's being used.
        By using our service or registering an account, you hereby consent to
        our Disclaimer and agree to its terms.
      </LegalNoticeText>
    ),
  },
  {
    title: "Compliance with tax obligations",
    content: (
      <>
        <LegalNoticeText>
          The users of PADRE are solely responsible for determining what, if
          any, taxes apply to their transactions made via PADRE. The owners of,
          or contributors to, PADRE, are NOT responsible for determining the
          taxes that apply to transactions made using PADRE services.
        </LegalNoticeText>
        <LegalNoticeText>
          PADRE shall not be liable for any incorrect tax settlement of the User
          for any reason with respect to the remuneration paid. PADRE does not
          provide investment, financial, tax or legal advice. Any information
          and applications posted on the Website/PADRE social media channels do
          not constitute investment advice, financial advice, commercial advice
          or any other type of advice.
        </LegalNoticeText>
        <LegalNoticeText>
          Users should always do their own research before deciding to invest in
          any project. PADRE, its owners, and its subsidiaries are not liable
          for any capital losses you might incur due to the user's investment
          decisions.
        </LegalNoticeText>
      </>
    ),
  },
  {
    title: "Arbitration",
    content: (
      <LegalNoticeText>
        The user of PADRE agrees to arbitrate any dispute arising from or in
        connection with PADRE or this disclaimer, except for disputes related to
        copyrights, logos, trademarks, trade names, trade secrets, or patents.
      </LegalNoticeText>
    ),
  },
  {
    title: "TradingView Services",
    content: (
      <>
        <LegalNoticeText>
          <ExternalTextLink
            label="TradingView"
            href={TRADING_VIEW_ATTRIBUTION_LINK}
            nofollow={false}
          />{" "}
          is a charting platform and social network used by millions of traders
          and investors worldwide to spot opportunities across global markets.
          It offers a range of tools and features designed to facilitate the
          analysis of financial markets. Additionally, TradingView provides
          materials on{" "}
          <ExternalTextLink
            label="how to use economic calendar for forex trading"
            href={TRADING_VIEW_ECONOMIC_CALENDAR_LINK}
            nofollow={false}
          />{" "}
          for any investor who wants to make informed decisions.
        </LegalNoticeText>
        <LegalNoticeText>
          Padre integrates TradingView’s services, adhering to all applicable
          terms, laws, and guidelines. TradingView’s distinct Branding, which is
          embedded within the content made available through their services, is
          duly displayed on our platform, accompanied by appropriate attribution
          and a direct hyperlink to TradingView’s official website. These
          components are used under a non-exclusive, royalty-free license from
          TradingView, under the terms provided by such parties.
        </LegalNoticeText>
        <LegalNoticeText>
          Padre expressly disclaims any warranty or other assurance to you
          regarding TradingView Services. Please note that your use of
          TradingView Services will be governed by the terms and conditions of
          use and privacy policies of the TradingView providers and not by these
          Terms of Use or our Privacy Policy.
        </LegalNoticeText>
      </>
    ),
  },
];
