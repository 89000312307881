import { Container, Stack } from "@mui/material";
import { ActionButton } from "src/components/ActionButton";
import { LogoLink } from "src/components/LogoLink";
import { HEADER_HEIGHT_MOBILE, HEADER_Z_INDEX } from "src/config";
import { EButtonId } from "src/contexts/Mixpanel";

export const HeaderMinimal = () => {
  return (
    <Stack
      component="header"
      justifyContent="center"
      sx={(theme) => ({
        position: "fixed",
        height: {
          xs: HEADER_HEIGHT_MOBILE,
        },
        width: "100%",
        zIndex: HEADER_Z_INDEX,
        backgroundColor: {
          xs: theme.palette.background.default,
        },
      })}
    >
      <Container>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <LogoLink />

          <ActionButton size="small" buttonId={EButtonId.HEADER} />
        </Stack>
      </Container>
    </Stack>
  );
};
