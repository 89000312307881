import { Logo } from "src/assets";
import { ROOT_APP_PATH } from "src/config";

import { DEFAULT_LOGO_SIZE, BIG_LOGO_SIZE } from "./config";

type TLogoLinkProps = {
  isBig?: boolean;
};

export const LogoLink = ({ isBig }: TLogoLinkProps) => (
  <a href={ROOT_APP_PATH}>
    <Logo {...(isBig ? BIG_LOGO_SIZE : DEFAULT_LOGO_SIZE)} />
  </a>
);
