import { Box } from "@mui/material";

type TTokenAvatarCircleProps = {
  src: string;
  zIndex: number;
};

export const TokenAvatarCircle = ({ src, zIndex }: TTokenAvatarCircleProps) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    sx={{
      zIndex,
      width: CIRCLE_SIZE,
      height: CIRCLE_SIZE,
      border: "2px solid #343434",
      backgroundColor: "rgba(24, 24, 27, 0.4)",
      borderRadius: "50%",
    }}
  >
    <img
      src={src}
      alt="avatar"
      style={{
        borderRadius: "50%",
        width: AVATAR_SIZE,
        height: AVATAR_SIZE,
      }}
    />
  </Box>
);

const CIRCLE_SIZE = 75;
const AVATAR_SIZE = 60;
