import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export const TosHeader = () => {
  const theme = useTheme();

  return (
    <Typography variant="h2" textTransform="uppercase" textAlign="center">
      <Typography color="primary" sx={{ font: "inherit", py: 1 }}>
        Padre.gg
      </Typography>
      <Typography color={theme.palette.text.primary} sx={{ font: "inherit" }}>
        terms and conditions & disclaimer
      </Typography>
    </Typography>
  );
};
