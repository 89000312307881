import { Stack } from "@mui/material";
import { Gallery } from "src/components/Gallery";
import { SECTION_ID_FEATURES } from "src/config";

import { FEATURE_GALLERY_ITEMS } from "./config";

export const FeaturesSection = () => (
  <Stack
    id={SECTION_ID_FEATURES}
    alignItems="center"
    my={{
      xs: 7.5,
      md: 27.5,
    }}
    mx={{
      xs: 0,
      md: 2,
    }}
  >
    <Gallery
      items={FEATURE_GALLERY_ITEMS}
      initialId={FEATURE_GALLERY_ITEMS[0].id}
    />
  </Stack>
);
