import { Stack, Typography, Box, Container } from "@mui/material";
import { HeaderMinimal } from "src/components/Header";
import { Article, LegalNotice } from "src/components/LegalNotice";

import { TosHeader } from "./components/TosHeader";
import { TosSubheader } from "./components/TosSubheader";
import { TOS_ARTICLES } from "./config";

export const ToS = () => {
  return (
    <>
      <HeaderMinimal />

      <Container component="main">
        <Stack py={12}>
          <TosHeader />

          <Typography
            variant="body2"
            textTransform="uppercase"
            textAlign="center"
            color="primary"
            sx={{ mt: 2.25 }}
          >
            By using padre gg services you agree to our terms and conditions.
          </Typography>

          <Box mt={5}>
            <TosSubheader />
          </Box>

          <Box mt={7}>
            <LegalNotice>
              {TOS_ARTICLES.map((article, articleIndex) => (
                <Article
                  key={article.title}
                  {...article}
                  articleNo={articleIndex + 1}
                />
              ))}
            </LegalNotice>
          </Box>
        </Stack>
      </Container>
    </>
  );
};
