import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { IArticleConfig } from "./types";

type TArticleProps = IArticleConfig & {
  articleNo: number;
};

export const Article = ({ articleNo, title, content }: TArticleProps) => {
  return (
    <Box>
      <Typography
        variant="h4"
        color="primary"
        textAlign="center"
        mb={1}
        sx={{
          fontVariantNumeric: "tabular-nums",
        }}
      >
        {articleNo}. {title.toUpperCase()}
      </Typography>
      <Box mt={3} display="flex" flexDirection="column" gap={2}>
        {content}
      </Box>
    </Box>
  );
};
