import { useCallback, useRef, useState } from "react";

import { Box, Stack, StackProps, Typography } from "@mui/material";
import { useMdOrUp } from "src/themes";

import { CompositeImage } from "../CompositeImage";
import { GradientBox } from "../GradientBox";
import { GradientTypography } from "../GradientTypography";
import { MAX_SECTION_WIDTH } from "../_pages/config";
import { GalleryItem, TGalleryItemProps } from "./GalleryItem";
import { TGalleryItem } from "./types";

type TGalleryProps = {
  items: TGalleryItem[];
  initialId: TGalleryItem["id"];
  wrapperProps?: StackProps;
};

export const Gallery = ({
  items,
  initialId,
  wrapperProps = {},
}: TGalleryProps) => {
  const [currentId, setCurrentId] = useState(initialId);

  const isMdOrUp = useMdOrUp();

  const onItemHeaderClick: TGalleryItemProps["onHeaderClick"] = useCallback(
    (id) => {
      setCurrentId(id);
    },
    []
  );

  const componentRef = useRef<HTMLDivElement | null>(null);

  return (
    <Stack
      gap={{
        xs: 6,
        md: 8,
      }}
      width="100%"
      justifyContent="space-between"
      direction={{ md: "row" }}
      {...wrapperProps}
      maxWidth={MAX_SECTION_WIDTH}
    >
      <Stack justifyContent={isMdOrUp ? "space-between" : "flex-start"} gap={6}>
        <Stack
          gap={{
            xs: 3,
            md: 5,
          }}
        >
          <GradientTypography variant="h1">
            Onboard in minutes
          </GradientTypography>
          <Typography variant="body1" color="text.disabled">
            Padre takes away as much complexity from the user as possible. Just
            sign up, top up your account and trade effortlessly.
          </Typography>
        </Stack>

        <Stack
          ref={componentRef}
          width="100%"
          gap={{
            xs: 1.5,
            md: 4,
          }}
        >
          {items.map((item, index) => {
            return (
              <GalleryItem
                key={index}
                item={item}
                isLast={index >= items.length - 1}
                isActive={item.id === currentId}
                onHeaderClick={onItemHeaderClick}
              />
            );
          })}
        </Stack>
      </Stack>

      <Box
        position="relative"
        width="100%"
        maxWidth={isMdOrUp ? MAX_IMAGES_DESKTOP_WIDTH : MAX_IMAGES_MOBILE_WIDTH}
        height={isMdOrUp ? PREVIEW_HEIGHT : MOBILE_PREVIEW_HEIGHT}
        alignSelf="center"
      >
        {items.map((item) => {
          return (
            <GradientBox
              width="100%"
              height="100%"
              justifyContent="center"
              key={item.id}
              py={0}
              alignItems="center"
              px={1}
              sx={{
                position: "absolute",
              }}
              shadeStrength={0.8}
            >
              <CompositeImage
                my="auto"
                imagesConfig={item.imagesConfig}
                isVisible={item.id === currentId}
              />
            </GradientBox>
          );
        })}
      </Box>
    </Stack>
  );
};

const PREVIEW_HEIGHT = 550;
const MOBILE_PREVIEW_HEIGHT = 350;
const MAX_IMAGES_DESKTOP_WIDTH = 1000;
const MAX_IMAGES_MOBILE_WIDTH = 550;
