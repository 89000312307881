import { forwardRef, ReactNode } from "react";

import Link, { LinkProps } from "@mui/material/Link";

type TExternalLinkBehaviorProps = {
  href: string;
  children: ReactNode;
  nofollow?: boolean;
} & Omit<LinkProps, "to">;

export const ExternalLinkBehavior = forwardRef<
  HTMLAnchorElement,
  TExternalLinkBehaviorProps
>(({ href, children, nofollow = true, ...restProps }, ref) => {
  return (
    <Link
      ref={ref}
      component="a"
      href={href}
      target="_blank"
      rel={`noopener${nofollow ? " nofollow" : ""}`}
      fontWeight="inherit"
      underline="always"
      {...restProps}
    >
      {children}
    </Link>
  );
});
