import { Footer } from "src/components/Footer";
import { Header } from "src/components/Header";

import { Main } from "./Main";

export const Landing = () => (
  <>
    <Header />

    <Main />

    <Footer />
  </>
);
