import { Stack, Typography } from "@mui/material";

import { GradientTypography } from "../GradientTypography";

type TStatProps = {
  value: string;
  label: string;
  minWidth?: number;
};

export const Stat = ({ value, label, minWidth }: TStatProps) => (
  <Stack gap={0.5} minWidth={minWidth}>
    <GradientTypography textAlign="center" variant="h5">
      {value}
    </GradientTypography>
    <Typography textAlign="center" variant="body2" color="text.disabled">
      {label}
    </Typography>
  </Stack>
);
