import { ReactNode } from "react";

import { Stack, Typography, StackProps } from "@mui/material";

import { GradientTypography } from "../GradientTypography";

type TInfoSectionProps = StackProps & {
  titles: string | ReactNode;
  text: string | ReactNode;
};

export const InfoSection = ({
  titles,
  text,
  ...stackProps
}: TInfoSectionProps) => (
  <Stack
    {...stackProps}
    gap={{
      xs: 2,
      md: 2.5,
    }}
    maxWidth={stackProps.maxWidth ?? 400}
  >
    <GradientTypography variant="h5" textAlign="center">
      {titles}
    </GradientTypography>

    <Typography variant="body2" color="text.disabled" textAlign="center">
      {text}
    </Typography>
  </Stack>
);
