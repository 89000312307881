import {
  Box,
  BoxProps,
  Divider,
  Stack,
  StackProps,
  Typography,
} from "@mui/material";
import { useMdOrUp } from "src/themes";

import { LEVEL_INFOS } from "./config";
import { TLevelInfo } from "./types";

export const TokenSectionTable = () => {
  const mdOrUp = useMdOrUp();

  if (mdOrUp) {
    return (
      <Stack height={270} gap={1} direction="row" width="100%">
        <Box {...commonTableBoxProps}>
          <Stack {...commonStackProps}>
            <Stack flex={1} justifyContent="center">
              <Typography variant="h6" fontSize={16} sx={{ opacity: 0.4 }}>
                level
              </Typography>
            </Stack>

            <Stack flex={1} justifyContent="center">
              <Typography variant="h6" fontSize={16} sx={{ opacity: 0.4 }}>
                $padre <br /> held
              </Typography>
            </Stack>

            <Stack flex={1} justifyContent="center">
              <Typography variant="h6" fontSize={16} sx={{ opacity: 0.4 }}>
                discount <br />
                on fees
              </Typography>
            </Stack>
          </Stack>
        </Box>
        {LEVEL_INFOS.map((levelInfo) => {
          return (
            <Box key={levelInfo.level} {...commonTableBoxProps}>
              <StandardTableContent {...levelInfo} />
            </Box>
          );
        })}
      </Stack>
    );
  }

  return (
    <Box {...commonTableBoxProps} width="100%">
      <Stack {...commonStackProps}>
        <Stack direction="row" width="100%" height="100%" pt={1} pb={1.25}>
          <Stack flex={1} justifyContent="center" alignItems="center">
            <Typography variant="h6" fontSize={16} sx={{ opacity: 0.4 }}>
              level
            </Typography>
          </Stack>

          <Stack flex={1} justifyContent="center" alignItems="center">
            <Typography variant="h6" fontSize={16} sx={{ opacity: 0.4 }}>
              $padre <br /> held
            </Typography>
          </Stack>

          <Stack flex={1} justifyContent="center" alignItems="center">
            <Typography variant="h6" fontSize={16} sx={{ opacity: 0.4 }}>
              discount <br />
              on fees
            </Typography>
          </Stack>
        </Stack>
        {LEVEL_INFOS.map((levelInfo) => {
          return <StandardRowContent key={levelInfo.level} {...levelInfo} />;
        })}
      </Stack>
    </Box>
  );
};

const commonTableBoxProps: BoxProps = {
  display: "flex",
  alignItems: "center",
  flex: 1,

  px: 2.5,
  py: 1,
  sx: {
    background:
      "linear-gradient(180deg, #18181B 0%, rgba(24, 24, 27, 0.40) 100%)",
    border: "1px solid #1B1B1B",
    borderRadius: "6px",
  },
};

const commonStackProps: StackProps = {
  width: "100%",
  height: "100%",
  divider: <Divider flexItem sx={{ backgroundColor: "#FFF", opacity: 0.35 }} />,
};

type TStandardContentProps = TLevelInfo;

const StandardTableContent = ({
  level,
  held,
  discount,
}: TStandardContentProps) => (
  <Stack {...commonStackProps}>
    <Stack flex={1} justifyContent="center" alignItems="center">
      <Typography variant="h6" fontSize={16} color="primary.main">
        {level}
      </Typography>
    </Stack>

    <Stack flex={1} justifyContent="center" alignItems="center">
      <Typography variant="h6" fontSize={16}>
        {held}
      </Typography>
    </Stack>

    <Stack flex={1} justifyContent="center" alignItems="center">
      <Typography variant="h6" fontSize={16}>
        {discount}
      </Typography>
    </Stack>
  </Stack>
);

const StandardRowContent = ({
  level,
  held,
  discount,
}: TStandardContentProps) => {
  return (
    <Stack direction="row" width="100%" height="100%" py={2}>
      <Stack flex={1} alignItems="center">
        <Typography variant="h6" fontSize={16} color="primary.main">
          {level}
        </Typography>
      </Stack>

      <Stack flex={1} alignItems="center">
        <Typography variant="h6" fontSize={16}>
          {held}
        </Typography>
      </Stack>

      <Stack flex={1} alignItems="center">
        <Typography variant="h6" fontSize={16}>
          {discount}
        </Typography>
      </Stack>
    </Stack>
  );
};
