import { Stack, Typography, Box } from "@mui/material";
import { EButtonId } from "src/contexts/Mixpanel";

import { ActionButton } from "../ActionButton";
import { EssenceAnimationPlayer } from "../EssenceAnimationPlayer";
import { GradientTypography } from "../GradientTypography";
import { TokenAvatarCircle } from "./TokenAvatarCircle";
import left1 from "./tokens/Left1.png";
import left2 from "./tokens/Left2.png";
import left3 from "./tokens/Left3.png";
import right1 from "./tokens/Right1.png";
import right2 from "./tokens/Right2.png";
import right3 from "./tokens/Right3.png";

export const SummarySection = () => (
  <Stack
    alignItems="center"
    gap={10}
    mt={{
      xs: 7.5,
      md: 22.5,
    }}
  >
    <Stack
      height={120}
      width="100%"
      sx={{
        alignItems: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          left: `calc(50% - ${AVATAR_OFFSET * 3 + ANIMATION_OFFSET}px)`,
          top: "50%",
        }}
      >
        <TokenAvatarCircle src={left1} zIndex={1} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          left: `calc(50% - ${AVATAR_OFFSET * 2 + ANIMATION_OFFSET}px)`,
          top: "50%",
        }}
      >
        <TokenAvatarCircle src={left2} zIndex={2} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          left: `calc(50% - ${AVATAR_OFFSET * 1 + ANIMATION_OFFSET}px)`,
          top: "50%",
        }}
      >
        <TokenAvatarCircle src={left3} zIndex={3} />
      </Box>

      <Box
        sx={{
          position: "absolute",
          right: `calc(50% - ${AVATAR_OFFSET * 3 + ANIMATION_OFFSET}px)`,
          top: "50%",
        }}
      >
        <TokenAvatarCircle src={right3} zIndex={3} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          right: `calc(50% - ${AVATAR_OFFSET * 2 + ANIMATION_OFFSET}px)`,
          top: "50%",
        }}
      >
        <TokenAvatarCircle src={right2} zIndex={2} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          right: `calc(50% - ${AVATAR_OFFSET * 1 + ANIMATION_OFFSET}px)`,
          top: "50%",
        }}
      >
        <TokenAvatarCircle src={right1} zIndex={1} />
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          position: "absolute",
          left: `calc(50% - 60px)`,
          top: "30%",
        }}
      >
        <EssenceAnimationPlayer width={120} height={120} showPadreIcon />
      </Box>
    </Stack>
    <Stack gap={4} alignItems="center">
      <GradientTypography variant="h5" textAlign="center">
        Trade your favorite meme coins <br /> & earn Essence
      </GradientTypography>

      <Typography
        variant="body1"
        color="text.disabled"
        textAlign="center"
        sx={{ maxWidth: 620 }}
      >
        Start trading on Padre today and experience the ease, security, and
        advanced features that elevate your crypto trading journey.
      </Typography>
      <ActionButton label="Trade now with Padre" buttonId={EButtonId.SUMMARY} />
    </Stack>
  </Stack>
);

const AVATAR_OFFSET = 50;
const ANIMATION_OFFSET = 70;
