import { Stack } from "@mui/material";
import { ActionButton } from "src/components/ActionButton";
import { FAQ_URL, SECTION_ID_FEATURES, YOUTUBE_URL } from "src/config";
import { DOCS_URL } from "src/config";
import { EButtonId } from "src/contexts/Mixpanel";
import { scrollToSection } from "src/hooks/scrollToSection";
import { BUTTON_SIZE_SMALL } from "src/themes/theme";

import { HeaderLink, THeaderLinkProps } from "./HeaderLink";

type THeaderContentProps = { isMuted?: boolean };

export const HeaderContent = ({ isMuted = false }: THeaderContentProps) => (
  <>
    {LINKS.map((linkItem, index) => (
      <HeaderLink key={index} {...linkItem} />
    ))}

    <Stack mt={{ xs: 2, md: 0 }}>
      <ActionButton
        size="large"
        sx={{
          height: isMuted ? BUTTON_SIZE_SMALL : undefined,
          minWidth: ACTION_BUTTON_MIN_WIDTH,
          transition: "height .2s ease-in-out",
        }}
        buttonId={EButtonId.HEADER}
      />
    </Stack>
  </>
);

const ACTION_BUTTON_MIN_WIDTH = { xs: 300, md: 149 };

const LINKS: THeaderLinkProps[] = [
  { text: "Features", onClick: () => scrollToSection(SECTION_ID_FEATURES) },
  { text: "Docs", href: DOCS_URL },
  { text: "FAQ", href: FAQ_URL },
  { text: "Tutorials", href: YOUTUBE_URL },
];
