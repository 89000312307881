import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const useEnrichedUrl = (baseUrl: string): string => {
  const location = useLocation();

  return useMemo(() => {
    if (!location.search) {
      return baseUrl;
    }
    const url = new URL(baseUrl);
    const currentParams = new URLSearchParams(location.search);
    currentParams.forEach((value, key) => {
      url.searchParams.append(key, value);
    });

    return url.toString();
  }, [baseUrl, location.search]);
};
