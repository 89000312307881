import { memo } from "react";

import { Link, Stack, StackProps, Typography } from "@mui/material";
import { useMdOrUp } from "src/themes";

import { TLinkGroup } from "./types";

type TLinkGroupProps = { group: TLinkGroup; wrapperProps?: StackProps };

export const LinkGroup = memo(
  ({ group, wrapperProps = {} }: TLinkGroupProps) => {
    const isMdOrUp = useMdOrUp();
    const listContent = group.items.map(({ name, href }, index) => (
      <Link
        key={index}
        href={href}
        target="_blank"
        whiteSpace="nowrap"
        sx={{
          textDecoration: "none",
          textAlign: isMdOrUp ? "start" : "center",
        }}
      >
        <Typography
          component="span"
          variant="body2"
          color="text.secondary"
          sx={{ "&:hover": { color: "primary.main" } }}
        >
          {name}
        </Typography>
      </Link>
    ));

    if (isMdOrUp) {
      return (
        <Stack gap={2} {...wrapperProps}>
          {listContent}
        </Stack>
      );
    }
    return (
      <Stack gap={2} pt={2} pb={4} {...wrapperProps}>
        {listContent}
      </Stack>
    );
  }
);

export const MOBILE_HEADER_PADDING = 2;
