import { useEffect, useRef, useState } from "react";

import { Box } from "@mui/material";
import { Loading } from "src/components/Loading";

import padreIcon from "./padre-icon.svg";

type TEssenceAnimationPlayerProps = {
  height?: string | number;
  width?: string | number;
  showPadreIcon?: boolean;
};

export const EssenceAnimationPlayer = ({
  height,
  width,
  showPadreIcon = false,
}: TEssenceAnimationPlayerProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleVideoLoad = () => {
      setIsLoading(false);
    };

    const videoElement = videoRef.current;

    if (videoElement) {
      videoElement.addEventListener("loadeddata", handleVideoLoad);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener("loadeddata", handleVideoLoad);
      }
    };
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        width: width ?? "100%",
        height: height ?? "auto",
        overflow: "hidden",
      }}
    >
      {isLoading && <Loading />}
      <video
        ref={videoRef}
        src={"/essenceAnimation.webm"}
        style={{
          display: isLoading ? "none" : "block",
          width: width ?? "100%",
          height: height ?? "auto",
          border: "none",
          borderRadius: "50%",
        }}
        loop
        muted
        autoPlay
        playsInline
        controls={false}
      />
      {showPadreIcon && (
        <Box
          position="absolute"
          top={`calc(50% + 2px)`}
          left="50%"
          sx={{
            transform: "translate(-50%, -50%)",
            zIndex: 2,
            opacity: 0.35,
          }}
        >
          <img src={padreIcon} alt="padre" />
        </Box>
      )}
    </Box>
  );
};
