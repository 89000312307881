import { Stack } from "@mui/material";
import { EssenceSection } from "src/components/EssenceSection";
import { PadreTokenSection } from "src/components/PadreTokenSection";
import { ReferencesSection } from "src/components/ReferencesSection";
import { SummarySection } from "src/components/SummarySection";
import { useMdOrUp } from "src/themes";

import { FeaturesIntroSection, FeaturesSection, HeroSection } from "./sections";

export const Main = () => {
  const mdOrUp = useMdOrUp();
  return (
    <Stack
      flexGrow={1}
      component="main"
      pb={{ xs: 15, md: 8 }}
      width="100%"
      overflow="hidden" //to avoid horizontal scrollbar when using large background gradients
      px={mdOrUp ? 2.5 : 1}
    >
      <HeroSection />

      <ReferencesSection />

      <EssenceSection />

      <FeaturesSection />

      <FeaturesIntroSection />

      <PadreTokenSection />

      <SummarySection />
    </Stack>
  );
};
