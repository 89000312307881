import { Stack, Typography, Box } from "@mui/material";

type TFeaturesIntroBoxContentProps = {
  title: string;
  text: string;
  imgSrc: string;
};

export const FeaturesIntroBoxContent = ({
  title,
  text,
  imgSrc,
}: TFeaturesIntroBoxContentProps) => (
  <Stack
    height="100%"
    justifyContent="start"
    alignItems="flex-start"
    sx={{
      position: "relative",
    }}
  >
    <Stack
      gap={{
        xs: 2,
        md: 3,
        zIndex: 2,
      }}
    >
      <Typography variant="h5" fontSize={20} textAlign="center">
        {title}
      </Typography>

      <Typography variant="body2" textAlign="center" color="text.disabled">
        {text}
      </Typography>
    </Stack>

    <Box
      component="img"
      ml="-60px"
      src={imgSrc}
      alt={title}
      bottom={0}
      position="absolute"
      height="auto"
      width="min(calc(100% + 120px), 705px)"
      zIndex={1}
    />
  </Stack>
);
