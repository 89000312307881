import { PropsWithChildren, useCallback, useRef, useState } from "react";
import { useSwipeable } from "react-swipeable";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, BoxProps, IconButton, Stack, useTheme } from "@mui/material";

import { useElementDimensions, useTouchScreen } from "../../hooks";
import { MAX_SECTION_WIDTH } from "../_pages/config";

type TCarousel = {
  count: number;
  isDisabled?: boolean;
  wrapperProps?: BoxProps;
};

export const Carousel = ({
  children,
  count,
  wrapperProps = {},
}: PropsWithChildren<TCarousel>) => {
  const relativeRef = useRef<HTMLDivElement>(null);
  const { width } = useElementDimensions(relativeRef);
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const isTouchScreen = useTouchScreen();

  const handleLeftClick = useCallback(
    () =>
      setActiveStep((prev) => (prev === 0 ? prev : (prev - 1 + count) % count)),
    [count]
  );

  const handleRightClick = useCallback(
    () =>
      setActiveStep((prev) => (prev === count - 1 ? prev : (prev + 1) % count)),
    [count]
  );

  const handlers = useSwipeable({
    onSwipedLeft: handleRightClick,
    onSwipedRight: handleLeftClick,
  });

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor="transparent"
      {...(isTouchScreen ? handlers : {})}
      {...wrapperProps}
    >
      <IconButton
        onClick={handleLeftClick}
        disabled={activeStep === 0}
        sx={{
          opacity: activeStep === 0 ? 0.5 : 1,
          mr: {
            xs: 1,
            md: 0.25,
          },
        }}
      >
        <ChevronLeftIcon
          sx={{
            color: theme.palette.primary.main,
            fontSize: { xs: 30, sm: 45, md: 60 },
          }}
        />
      </IconButton>

      <Stack
        flexGrow={1}
        alignItems="center"
        justifyContent="flex-end"
        height="100%"
        position="relative"
        overflow="hidden"
        ref={relativeRef}
        maxWidth={MAX_SECTION_WIDTH}
      >
        <Box
          position="absolute"
          display="flex"
          sx={{
            height: "100%",
            width,
            top: 0,
            left: -1 * activeStep * width,
            willChange: "transform",
            transition: "0.5s",
            gap: 1,
          }}
        >
          {children}
        </Box>
      </Stack>

      <IconButton
        onClick={handleRightClick}
        disabled={activeStep === count - 1}
        sx={{
          opacity: activeStep === count - 1 ? 0.5 : 1,
        }}
      >
        <ChevronRightIcon
          sx={{
            color: theme.palette.primary.main,
            fontSize: { xs: 30, sm: 45, md: 60 },
          }}
        />
      </IconButton>
    </Box>
  );
};
