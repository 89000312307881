import { forwardRef, PropsWithChildren } from "react";

import { Button, Typography, ButtonProps, useTheme } from "@mui/material";

const ExternalLink = forwardRef<
  HTMLAnchorElement,
  React.HTMLProps<HTMLAnchorElement>
>((props, ref) => (
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  <a ref={ref} {...props} target="_blank" rel="noopener noreferrer" />
));

type TDecorativeButtonProps = ButtonProps & PropsWithChildren;

export const DecorativeButton = ({
  children,
  ...buttonProps
}: TDecorativeButtonProps) => {
  const theme = useTheme();

  return (
    <Button
      LinkComponent={ExternalLink}
      variant="contained"
      {...buttonProps}
      sx={{
        maxWidth: {
          xs: 161,
          md: 234,
        },
        position: "relative",
        overflow: "hidden",
        height: 47,
        px: { xs: 1, md: 4 },
        backgroundColor: theme.palette.primary.main,
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
        "&:hover": {
          backgroundColor: theme.palette.primary.main,
          transform: "translateY(-2px)",
          boxShadow: `0px 0px 25px 0px ${theme.palette.primary.main}80`,
        },
        "&::before": {
          content: '""',
          position: "absolute",
          top: "0",
          right: "0",
          width: "100%",
          height: "100%",
          background: buttonProps.disabled
            ? "none"
            : "linear-gradient(to left, yellow, transparent)",
          opacity: "0.2",
          transition: "opacity 0.3s ease",
        },
        ...buttonProps.sx,
      }}
    >
      <Typography variant="body2" fontWeight={600} color="inherit">
        {children}
      </Typography>
    </Button>
  );
};
