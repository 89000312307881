import {
  Reference1,
  Reference2,
  Reference3,
  Reference4,
  Reference5,
  Reference6,
  Reference7,
  Reference8,
  Reference9,
} from "src/assets";

import { TStepDisplayProps } from "./StepDisplay";

export const STEPS: TStepDisplayProps[] = [
  {
    text: "Personally, I use Padre daily. I got tired of constantly switching between Uniswap, Aerodrome, and Jupiter. It's an amazing simplification of trading from one level, with a friendly UI and features that no one else has.",
    imgSrc: Reference1,
    link: "https://x.com/0xlav7/status/1810317378644758654",
  },
  {
    text: "@PadreApp has the best UI/UX of trading terminals in my experience, so there is room to attract a bigger market share of traders who are done with TG or wallet trading.",
    imgSrc: Reference2,
    link: "https://x.com/SharkSociety_/status/1784177065656226126",
  },

  {
    text: "$PADRE is designed to cater all your needs. It will give you the best user experience on trading!",
    imgSrc: Reference3,
    link: "https://x.com/ManilaMax1/status/1784404729990443014",
  },
  {
    text: "Clean trading terminal with a nice UI/UX",
    imgSrc: Reference4,
    link: "https://x.com/knypher/status/1784456790517956935",
  },
  {
    text: "The legends over at @PadreApp are not only building a game changing multi network trading interface but also offering rewards for using it. Win win",
    imgSrc: Reference5,
    link: "https://x.com/0xBoko/status/1816798666436247826",
  },

  {
    text: "This is for all my normies followers, I will never let you down. @PadreApp is cooking to make your life easier",
    imgSrc: Reference6,
    link: "https://x.com/TyreksRoaaarrrr/status/1807769247830950015",
  },
  {
    text: "$PADRE introduces the Divine Way to Trade - @PadreApp is the no. 1 on-chain trading platform in the making!",
    imgSrc: Reference7,
    link: "https://x.com/1CrypTina/status/1816771053869294051",
  },
  {
    text: "Supporting utility products at early stage pays off guys. Make sure you check @PadreApp for future raffles and their amazing dex already working on $sol $eth and base",
    imgSrc: Reference8,
    link: "https://x.com/JustDanielK2/status/1816043431258456395",
  },

  {
    text: "Trading via @PadreApp as it's the most comfortable place for me to set my Limit Buy & Sell Orders, and track my portfolio in one place for $ETH plays.",
    imgSrc: Reference9,
    link: "https://x.com/DEXLaboratory4/status/1768926575125299429",
  },
];
