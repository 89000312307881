import { ReactNode } from "react";

import Box from "@mui/material/Box";

import { LEGAL_NOTICE_FONT_SIZE, LEGAL_NOTICE_LINE_HEIGHT } from "./styles";

interface ILegalNoticeProps {
  children: ReactNode;
}

export const LegalNotice = ({ children }: ILegalNoticeProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={5}
      sx={{
        "& ol, & ul, & p, & span": {
          fontWeight: 500,
          fontSize: LEGAL_NOTICE_FONT_SIZE,
          lineHeight: LEGAL_NOTICE_LINE_HEIGHT,
        },
      }}
    >
      {children}
    </Box>
  );
};
