import { Stack, StackProps } from "@mui/material";

export const Wrapper = (props: StackProps) => (
  <Stack
    data-testid="loading-animation"
    width="100%"
    height="100%"
    alignItems="center"
    justifyContent="center"
    gap={2}
    {...props}
  />
);
