import {
  FamiliarCex,
  Passkey1,
  Passkey2,
  Performance1,
  Performance2,
} from "src/assets";
import { TGalleryItem } from "src/components/Gallery";

type TSourceDimesions = {
  bottomLayerWidth: number;
  topLayerWidth: number;
  totalWidth: number;
  totalHeight: number;
  topOffset?: number;
};

const DIMESIONS: Record<string, TSourceDimesions> = {
  CEX: {
    bottomLayerWidth: 1000,
    topLayerWidth: 307,
    totalWidth: 1000,
    totalHeight: 600,
  },
  PASSKEY: {
    bottomLayerWidth: 400,
    topLayerWidth: 400,
    totalWidth: 820,
    totalHeight: 630,
  },
  PERFORMANCE: {
    bottomLayerWidth: 600,
    topLayerWidth: 260,
    totalWidth: 600,
    totalHeight: 475,
    topOffset: 6,
  },
};

const getBottomLayerWidth = ({
  bottomLayerWidth,
  totalWidth,
}: TSourceDimesions) => `${Math.round((bottomLayerWidth / totalWidth) * 100)}%`;

const getTopLayerWidth = ({ topLayerWidth, totalWidth }: TSourceDimesions) =>
  `${Math.round((topLayerWidth / totalWidth) * 100)}%`;

const getRatio = ({ totalHeight, totalWidth }: TSourceDimesions) =>
  `${((totalHeight / totalWidth) * 100).toFixed(2)}%`;

export const FEATURE_GALLERY_ITEMS: TGalleryItem[] = [
  {
    id: 0,
    title: (
      <>
        Familiar
        <br />
        cex-like interface
      </>
    ),
    imagesConfig: {
      layers: [
        {
          src: FamiliarCex,
          bottom: 0,
          top: -16,
          left: 0,
          width: getBottomLayerWidth(DIMESIONS.CEX),
        },
      ],
      width: "100%",
      paddingTop: getRatio(DIMESIONS.CEX),
    },
  },
  {
    id: 1,
    title: "Best-in-class mobile support",
    imagesConfig: {
      layers: [
        {
          src: Passkey1,
          bottom: -26,
          right: 0,
          width: getBottomLayerWidth(DIMESIONS.PASSKEY),
        },
        {
          src: Passkey2,
          top: 4,
          left: 0,
          width: getTopLayerWidth(DIMESIONS.PASSKEY),
        },
      ],
      width: "100%",
      paddingTop: getRatio(DIMESIONS.PASSKEY),
    },
  },
  {
    id: 2,
    title: (
      <>
        Top performance <br /> & reliability
      </>
    ),
    imagesConfig: {
      layers: [
        {
          src: Performance1,
          top: 4,
          left: 0,
          width: getBottomLayerWidth(DIMESIONS.PERFORMANCE),
        },
        {
          src: Performance2,
          bottom: 0,
          right: 0,
          width: getTopLayerWidth(DIMESIONS.PERFORMANCE),
          topOffset: DIMESIONS.PERFORMANCE.topOffset,
        },
      ],
      width: "100%",
      paddingTop: getRatio(DIMESIONS.PERFORMANCE),
    },
  },
];
