import { Box, BoxProps } from "@mui/material";

import { getHorizontalMultiplier, getVerticalMultiplier } from "./helpers";
import { TCompositeImagesConfig } from "./types";

type TCompositeImageProps = BoxProps & {
  imagesConfig: TCompositeImagesConfig;
  isVisible?: boolean;
};

export const CompositeImage = ({
  imagesConfig: { layers, ...wrapperProps },
  isVisible,
  ...wrapperPropsOverrides
}: TCompositeImageProps) => (
  <Box position="relative" {...wrapperProps} {...wrapperPropsOverrides}>
    {layers.map(
      ({ src, alt, fillWidth = true, topOffset, ...positionProps }, index) => (
        <Box
          key={index}
          position="absolute"
          sx={(theme) => ({
            opacity: isVisible ? 1 : 0,
            transform: isVisible
              ? "translateY(0)"
              : `translateY(${
                  getVerticalMultiplier(positionProps) * TRANSLATION_OFFSET
                }px)`,
            transition: `opacity ${theme.transitions.duration.standard}ms ease-in-out, transform ${theme.transitions.duration.complex}ms ease-out`,
            mx: topOffset,
          })}
          {...positionProps}
        >
          {index > 0 && (
            <Box
              position="absolute"
              sx={{
                inset: SHADOW_INSET,
                borderRadius: 6,
                backgroundColor: "black",
                filter: `blur(${1.5 * SHADOW_OFFSET}px)`,
                opacity: 0.5,
                transform: `translate(${
                  getHorizontalMultiplier(positionProps) * SHADOW_OFFSET
                }px, ${
                  getVerticalMultiplier(positionProps) * SHADOW_OFFSET
                }px)`,
              }}
            />
          )}

          <img
            src={src}
            alt={alt}
            style={{ position: "relative", maxHeight: "100%" }}
            {...(fillWidth ? { width: "100%" } : {})}
          />
        </Box>
      )
    )}
  </Box>
);

const SHADOW_OFFSET = 20;
const SHADOW_INSET = SHADOW_OFFSET / 10;
const TRANSLATION_OFFSET = -10;
