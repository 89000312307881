import { useCallback, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Backdrop,
  Box,
  Container,
  IconButton,
  Stack,
  Typography,
  alpha,
} from "@mui/material";
import baseIcon from "src/assets/chainIcons/base.svg";
import bscIcon from "src/assets/chainIcons/bsc.svg";
import ethIcon from "src/assets/chainIcons/eth.svg";
import solanaIcon from "src/assets/chainIcons/solana.svg";
import { LogoLink } from "src/components/LogoLink";
import {
  HEADER_HEIGHT_DESKTOP,
  HEADER_HEIGHT_DESKTOP_SCROLLED,
  HEADER_HEIGHT_MOBILE,
  HEADER_Z_INDEX,
} from "src/config";
import { useIsScrolledDown } from "src/hooks/useIsScrolledDown";
import { useMdOrUp } from "src/themes";

import { HeaderContent } from "./HeaderContent";
import menuIcon from "./menu.svg";

export const Header = () => {
  const isMdOrUp = useMdOrUp();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isScrolledDown = useIsScrolledDown();

  const handleOpenMenu = useCallback(() => {
    setIsMenuOpen(true);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  return (
    <Stack
      component="header"
      justifyContent="center"
      sx={(theme) => ({
        position: "fixed",
        height: {
          xs: HEADER_HEIGHT_MOBILE,
          md: isScrolledDown
            ? HEADER_HEIGHT_DESKTOP_SCROLLED
            : HEADER_HEIGHT_DESKTOP,
        },
        width: "100%",
        zIndex: HEADER_Z_INDEX,
        backgroundColor: {
          xs: theme.palette.background.default,
          md: isScrolledDown ? theme.palette.background.default : "transparent",
        },
        transition:
          "background-color 200ms ease-in-out, height 300ms ease-in-out",
      })}
    >
      <Container>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <LogoLink isBig={isMdOrUp} />
          {isMdOrUp ? (
            <Box component="nav" display="flex" alignItems="center" gap={4}>
              <HeaderContent isMuted={isScrolledDown} />
            </Box>
          ) : (
            <>
              <IconButton onClick={handleOpenMenu}>
                <img src={menuIcon} alt="menu" />
              </IconButton>
              <Backdrop
                sx={(theme) => ({
                  zIndex: 10,
                  height: "520px",
                  width: "100vw",
                  backdropFilter: "blur(4px)",
                  bgcolor: alpha(theme.palette.background.default, 0.8),
                })}
                open={isMenuOpen}
                onClick={handleCloseMenu}
              >
                <Container
                  sx={{
                    width: "100%",
                    height: "100%",
                    py: 2,
                  }}
                >
                  <Stack pt={8} position="relative">
                    <Box position="absolute" left={0} top={16}>
                      <LogoLink />
                    </Box>
                    <Box
                      position="absolute"
                      right={12} //to accomodate scrollbar width
                      top={0}
                    >
                      <IconButton onClick={handleCloseMenu}>
                        <CloseIcon
                          sx={{ fontSize: 40, color: "primary.main" }}
                        />
                      </IconButton>
                    </Box>

                    <Stack gap={3} right={0} alignItems="center">
                      <HeaderContent />

                      <Stack gap={2.5}>
                        <Typography
                          variant="caption"
                          textAlign="center"
                          sx={{
                            opacity: 0.4,
                          }}
                        >
                          Available on
                        </Typography>
                        <Stack
                          direction="row"
                          gap={2.5}
                          justifyContent="center"
                        >
                          <img src={solanaIcon} alt="solana" />
                          <img src={ethIcon} alt="eth" />
                          <img src={baseIcon} alt="base" />
                          <img src={bscIcon} alt="bsc" />
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </Container>
              </Backdrop>
            </>
          )}
        </Stack>
      </Container>
    </Stack>
  );
};
