import {
  DISCORD_TICKET_URL,
  DOCS_URL,
  FAQ_URL,
  TOS_URL,
  YOUTUBE_URL,
} from "src/config";

import { TLinkGroup } from "./types";

export const FOOTER_LINK_GROUPS: TLinkGroup[] = [
  {
    items: [
      { name: "FAQ", href: FAQ_URL },
      { name: "Discord ticket", href: DISCORD_TICKET_URL },
    ],
  },
  {
    items: [
      { name: "Terms of Service", href: TOS_URL },
      { name: "Docs", href: DOCS_URL },
      { name: "Tutorials", href: YOUTUBE_URL },
    ],
  },
];
