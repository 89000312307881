import {
  createContext,
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { type Mixpanel } from "mixpanel-browser";
import mixpanel from "mixpanel-browser";

import {
  EMPTY_MIXPANEL_CONTEXT_VALUE,
  MIXPANEL_COMMON_PROPERTIES,
  MIXPANEL_CONFIG,
  MIXPANEL_TOKEN,
  BUTTON_CLICKED_EVENT_NAME,
  PAGE_VIEW_EVENT_NAME,
} from "./config";

export type TMixpanelContext = {
  mixpanel: Mixpanel | null;
  trackButtonClicked: (buttonId: string) => void;
};

export const MixpanelContext = createContext<TMixpanelContext>(
  EMPTY_MIXPANEL_CONTEXT_VALUE
);

MixpanelContext.displayName = "MixpanelContext";

export const MixpanelContextProvider = memo(
  ({ children }: PropsWithChildren) => {
    const [mixpanelClient, setMixpanelClient] = useState<Mixpanel | null>(null);

    const [pageViewEventSent, setPageViewEventSent] = useState(false);

    useEffect(() => {
      if (mixpanelClient || !MIXPANEL_TOKEN) {
        return;
      }
      const padreMixpanel = mixpanel.init(
        MIXPANEL_TOKEN,
        MIXPANEL_CONFIG,
        "padre-mixpanel-landing"
      );
      padreMixpanel.register(MIXPANEL_COMMON_PROPERTIES);
      setMixpanelClient(padreMixpanel);
    }, [mixpanelClient, setMixpanelClient]);

    const trackButtonClicked = useCallback(
      (buttonId: string) => {
        if (!mixpanelClient) {
          return;
        }
        mixpanelClient.track(BUTTON_CLICKED_EVENT_NAME, {
          ...MIXPANEL_COMMON_PROPERTIES,
          buttonId,
        });
      },
      [mixpanelClient]
    );

    useEffect(() => {
      if (pageViewEventSent || !mixpanelClient) {
        return;
      }
      setPageViewEventSent(true);
      mixpanelClient.track(PAGE_VIEW_EVENT_NAME, MIXPANEL_COMMON_PROPERTIES);
    }, [pageViewEventSent, mixpanelClient]);

    const value = useMemo<TMixpanelContext>(
      () => ({
        mixpanel: mixpanelClient,
        trackButtonClicked,
      }),
      [mixpanelClient, trackButtonClicked]
    );

    return (
      <MixpanelContext.Provider value={value}>
        {children}
      </MixpanelContext.Provider>
    );
  }
);
