import { Typography } from "@mui/material";

export const PadreText = () => (
  <Typography
    sx={{ font: "inherit" }}
    color="primary"
    textTransform="uppercase"
    component="span"
  >
    Padre
  </Typography>
);
