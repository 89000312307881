import { Link } from "@mui/material";

import { ShadeButton } from "../ShadeButton";

export interface THeaderLinkProps {
  text: string;
  onClick?: () => void;
  href?: string;
}

export const HeaderLink = ({ text, onClick, href }: THeaderLinkProps) => {
  if (href) {
    return (
      <Link
        href={href}
        target="_blank"
        whiteSpace="nowrap"
        sx={{ textDecoration: "none" }}
        display="flex"
        alignItems="center"
      >
        <ShadeButton disableRipple>{text}</ShadeButton>
      </Link>
    );
  }

  return (
    <ShadeButton onClick={onClick} disableRipple>
      {text}
    </ShadeButton>
  );
};
